import React from "react"

const Contact: React.FC = () => (
  <html style={{ margin: 0, height: "100%", overflow: "hidden" }}>
    {" "}
    <head>
      {" "}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />{" "}
      <title>Contact us</title>{" "}
    </head>{" "}
    <body>
      {" "}
      <iframe
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          border: 0,
        }}
        id="typeform-full"
        width="100%"
        height="100%"
        frameBorder="0"
        allow="camera; microphone; autoplay; encrypted-media;"
        src="https://maxrewards.typeform.com/to/wRfc8z"
      ></iframe>{" "}
      <script
        type="text/javascript"
        src="https://embed.typeform.com/embed.js"
      ></script>{" "}
    </body>{" "}
  </html>
)

export default Contact
